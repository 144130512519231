import React, { useContext } from "react"
import LanguageContext from "../../helpers/context"
import translate from "../../helpers/translate"
import pdf from "../../assets/2.variants.pdf"
import MenuBar from "../menuBar/menuBar"
import "./catalogue.css"

export default function Catalogue() {
    const { language } = useContext(LanguageContext)

    return (
        <>
        <MenuBar/>
        <section className="catalogue">
            <h2>{translate(language, "catalogue")}</h2>

             <p dangerouslySetInnerHTML={{ __html: translate(language, "catalogue-text")}}></p>
            <iframe src={pdf} title="Client catalogue" width="1200px" height="700px" />

        </section>
        </>
    )
}