import React, { useContext, useState } from "react"
import "./contacts.css"
import LanguageContext from "../../helpers/context"
import translate from "../../helpers/translate"
import picture from "../../assets/main3.jpeg"
import axios from 'axios';
import MenuBar from "../menuBar/menuBar"
import Modal from "./modal/sucessmodal"

export default function Contacts() {
    const { language } = useContext(LanguageContext)
    const [openmodal, setOpenmodal] = useState(false)
    const [status, setstatus] = useState("sucess")

    const send = (e) => {
        e.preventDefault()

        const data = {
            name: e.target[0].value,
            email: e.target[1].value,
            text: e.target[2].value
        }

            // axios.post('#', { data: data }, { headers: { 'content-type': 'application/x-www-form-urlencoded' } })
            //   .then(response => setstatus(response.data.status))
            //     .then(setOpenmodal(true))
            //     .catch(error => {
            //         setOpenmodal(true)
            //     });
    }

    const closemodal = () => {
        setOpenmodal(false)
    }

    return (
        <>
            <MenuBar />
            <section className="contacts">
                <h2>{translate(language, "contacts")}</h2>
                <div className="row">
                    <span>
                        <img src={picture} alt="" width="500px" height="600px" />
                    </span>
                    <div className="form">
                        <form onSubmit={send}>
                            <input id="username" type="text" required name="name" placeholder={translate(language, "form-name")} />
                            <input type="email" name="email" placeholder={translate(language, "form-email")} />
                            <input type="phone" name="phone" placeholder={translate(language, "form-phone")} />
                            <textarea name="message" rows="4" cols="50" placeholder={translate(language, "form-message")} />
                            <button type="submit">{translate(language, "send")}</button>
                        </form>

                        <div className="menu-contacts">
                            info@lienesconsulting.com
                            <br />
                            + 46 70 722 77 19
                            <br />
                            + 371 29 121 844
                        </div >
                    </div>
                </div>
            </section>
            {openmodal && <Modal close={closemodal} status={status}/>}
        </>
    )
}