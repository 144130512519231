import React, { useContext, useEffect, useState } from "react"
import "./innerpage.css"
import { useParams } from 'react-router-dom';
import MenuBar from "../menuBar/menuBar";
import LanguageContext from "../../helpers/context"
import translate from "../../helpers/translate"
import picture from "../../assets/girl1.jpeg"
import Errorpage from "../errorpage/errorpage";

export default function Innerpage() {

    const params = useParams()
    const { title } = params
    const { language } = useContext(LanguageContext)
    const [page, setPage] = useState("about")

    useEffect(() => {
        setPage(title)
        window.scrollTo(0, 0);
    }, [title])
const options = ["about", "services"]


    if(!options.includes(title)){
        return <Errorpage/>
    }

  
    return (
        <>
            <MenuBar />
            <section className="inner">
                <h2>{translate(language, page)}</h2>
                <img src={picture} alt="" width="400px" height="100%" />

                <div className="textfield">

                    <p dangerouslySetInnerHTML={{ __html: translate(language, `${page}-text`) }}></p>
                </div>
            </section>
        </>
    )
}