import React, { useState } from "react"
import "./App.css"
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import LanguageContext from "./helpers/context"
import Footer from "./components/footer/footer"
import Home from "./components/home/home";
import Contacts from "./components/contacts/contacts";
import Innerpage from "./components/innerpage/innerpage";
import Catalogue from "./components/catalogue/catalogue";
import Errorpage from "./components/errorpage/errorpage";

export default function App() {
  const [language, setLanguage] = useState("LV")

  const router = createBrowserRouter([
    {
      path: "*",
       element: (<Errorpage/>)
    },
    {
      path: "/",
    errorElement: <Errorpage/>,
      element: (<Home/>),
    },
    {
      path: "/contacts",
      errorElement: <Errorpage/>,
      element: (<Contacts />),
    },
    {
      path: "/:title",
      errorElement: <Errorpage/>,
      element: (<Innerpage props={":title"}/>)
    },
    {
      path: "/catalogue",
      errorElement: <Errorpage/>,
      element: (<Catalogue />)
    }

  ])


  return (
    <>
    <LanguageContext.Provider value={{ language, setLanguage }}>
      <RouterProvider router={router} />

      <Footer />
    </LanguageContext.Provider>
    </>
  )
}