import React, { useContext } from "react"
import "./footer.css"
import { ImFacebook2 } from "react-icons/im";
import { FaLinkedin } from "react-icons/fa";
import pdf from "../../assets/2.variants.pdf"
import translate from "../../helpers/translate";
import LanguageContext from "../../helpers/context"
import { APIProvider, Map, Marker, useMarkerRef } from '@vis.gl/react-google-maps';

export default function Footer() {
    const { language } = useContext(LanguageContext)
     const [markerRef, marker] = useMarkerRef();
    const API_KEY = process.env.REACT_APP_API_KEY

    return (
        <footer>
            <div className="adress">
                <div className="map">
                    <APIProvider apiKey={API_KEY} libraries={['marker']}>
                        <Map
                            zoom={14}
                            center={{ lat: 59.270420, lng: 15.184940 }}
                            gestureHandling={'greedy'}
                            disableDefaultUI={true}
                        >
                            <Marker ref={markerRef} position={{ lat: 59.270420, lng: 15.184940 }} />
                        </Map>
                    </APIProvider>
                 
                </div>

                <div className="onMap">
                    <h3>{translate(language, "adress")}</h3>
                    <p>Västra Vintergatan 25 70344 Örebro</p>
                    <p>{translate(language, "working-hours")}</p>
                    <h3>{translate(language, "contacts")}</h3>
                    <p>info@lienesconsulting.com</p>
                    <p>+ 46 70 722 77 19</p>
                    <p>+ 371 29 121 844</p>
                </div>

            </div>
            <div className="row-data">
                <div className="soc">
                    <a href="https://www.linkedin.com/company/lienes-consulting-ab/" target="blabk"><FaLinkedin size={29} color="hsl(212, 80%, 42%)" /></a>
                    <a href="https://www.facebook.com/lienesconsulting/" target="_blabk"><ImFacebook2 size={25} color="hsl(218, 100%, 52%)" /></a>
                </div>
                <a href={pdf} target="_blank" rel="noreferrer" >{translate(language, "privacy")}</a>
                <p>© 2023 Lienes Consulting AB
                    <br />Org. no . 559330-2101
                </p>
            </div>
        </footer>
    )
}