import React from "react"

import "./partners.css"
import dati from "../../../helpers/data.json"

export default function Partners() {

    return (
        <div className="logo-slider">
            <div className="logo-slide-track">
                {dati.data.map(x => <div key={x.id} className="slide">
                    <a href={x.url} target="_blank" rel="noreferrer" ><img src={x.src} alt={x.alt} width="160px"/></a>
                </div>
                )}
            </div>
        </div>
    )
}