import React, { useState, useContext } from "react"
import "./menuBar.css"
import Logo from "../../assets/image.png"
import LanguageContext from "../../helpers/context"
import Menu from "./menuRender/menu"
import { Link } from "react-router-dom";

export default function MenuBar() {
    const [open, setOpen] = useState(false)
    const { language, setLanguage } = useContext(LanguageContext)
    const [menuopen, setMenuopen] = useState(false)

    const option = () => {
        setOpen(current => !current)
    }

    const openmenu = () => {
        setMenuopen(current => !current)
    }

    const check = (e) => {
        let value = e.target.value
        switch (value) {
            case 1:
                setLanguage("LV")
                break;
            case 2:
                setLanguage("EN")
                break;
            case 3:
                setLanguage("SWE")
                break;
            default:
                setLanguage("LV")
        }
        setOpen(false)
    }


    return (
        <>
            <div className={menuopen ? "bar fixed" : "bar"}>
                <a href="/"> <img src={Logo} alt="logo" height="100px" width="200px" /></a>
                <span>
                    <div className="dropdown" onClick={option}>{language}<i className={open ? "arrow up" : "arrow down"} /></div>
                    {open && <div className="options" >
                        <ul>
                            <li className={language === "LV" ? "selected" : ""} value={1} onClick={check}>LV</li>
                            <li className={language === "EN" ? "selected" : ""} value={2} onClick={check}>EN</li>
                            <li className={language === "SWE" ? "selected" : ""} value={3} onClick={check}>SWE</li>

                        </ul>
                    </div>
                    }
                    <p><Link to="/contacts" /></p>
                    <div id="hamburger" onClick={openmenu}>
                        <div className={menuopen ? "line transform-up" : "line"}></div>
                        <div className={menuopen ? "line transform-down" : "line"}></div>
                    </div>
                </span>
            </div>
            {menuopen && <Menu open={menuopen} openmenu={openmenu} />}
        </>
    )
}