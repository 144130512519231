import React, {useState, useContext} from "react"
import { FaQuoteLeft } from "react-icons/fa";
import { FaQuoteRight } from "react-icons/fa";
import LanguageContext from "../../../helpers/context"
import translate from "../../../helpers/translate"
import "./testimonials.css"

export default function Testimonials(){

    const [currentIndex, setCurrentIndex] = useState(0);
    const [active, setActive] = useState(0)
    const { language } = useContext(LanguageContext)


    const testimonials = [
        {
            text: "In order to provide our customers with a comprehensive solution, we also collaborate with experts in finance and taxes in Sweden and Latvia ",
            author: "Annie"
        },
        {
            text: "cits teksts We at Lienes Consulting AB are specialists in finding the right business contacts in Latvia.",
            author: "John"
        },
        {
            text: "trešais teksts",
            author: "Peteris"
        }
    ]

    const slide = (e) => {
        let index = e.target.getAttribute("index")
        let value = parseInt(index)
        setCurrentIndex(value)
        setActive(value)
    }

    const minusSlides = (e) => {

        if (currentIndex === testimonials.length - 1) {
            setCurrentIndex(0);
            setActive(0)

        } else {
            setCurrentIndex(currentIndex + 1);
            setActive(currentIndex + 1)

        }
    }

    const plusSlides = (e) => {

        if (currentIndex === 0) {
            setCurrentIndex(testimonials.length - 1);
            setActive(testimonials.length -1)

        } else {
            setCurrentIndex(currentIndex - 1);
            setActive(currentIndex -1)

        }
    }


    document.addEventListener('touchstart', handleTouchStart, false);
    document.addEventListener('touchmove', handleTouchMove, false);

    var xDown = null;
    var yDown = null;

    function getTouches(evt) {
        return evt.touches ||             // browser API
            evt.originalEvent.touches; // jQuery
    }

    function handleTouchStart(evt) {
        const firstTouch = getTouches(evt)[0];
        xDown = firstTouch.clientX;
        yDown = firstTouch.clientY;
    };

    function handleTouchMove(evt) {
        if (!xDown || !yDown) {
            return;
        }

        var xUp = evt.touches[0].clientX;
        var yUp = evt.touches[0].clientY;

        var xDiff = xDown - xUp;
        var yDiff = yDown - yUp;

        if (Math.abs(xDiff) > Math.abs(yDiff)) {/*most significant*/
            if (xDiff > 0) {
                /* right swipe */
                if (currentIndex + 1 !== testimonials.length) {
                    minusSlides()
                }
                const note = document.querySelector('.swipe');
                if (note) {
                    note.style.display = "none"
                }
            } else {
                /* left swipe */
                if (currentIndex !== 0) {
                    plusSlides()
                }
                const note = document.querySelector('.swipe');
                if (note) {
                    note.style.display = "none"
                }
            }
        }
        /* reset values */
        xDown = null;
        yDown = null;
    };


   return(
    <div className="testimonials">
    <h2>{translate(language, "testimonials")}</h2>
    <div className="slider">
        <FaQuoteLeft className="left" size={30} />

        <p>{testimonials[currentIndex].text}</p>
        <FaQuoteRight className="right" size={30} />

        <br />
        <p className="author">{testimonials[currentIndex].author}</p>
        <span>
            {testimonials.map((x, index) => <div key={x.text} index={index} className={active === index ? "dot active" : "dot"} onClick={slide} />)}
        </span>

    </div>
    </div>
    )
}