import React, {useContext} from "react"
import "./sucessmodal.css"
import LanguageContext from "../../../helpers/context"
import translate from "../../../helpers/translate"

export default function Modal({close, status}) {
    const { language } = useContext(LanguageContext)

    return (
        <section className="modal">
            <div>
            {status === "sucess" ?( <span className="ok"> &#10004;</span>) : (<span className="error"> &#10008;</span>)}
                <p>{status === "sucess" ? translate(language, "sucess") : translate(language, "errormessage")}</p>
                <div className="button" onClick={close}>OK</div>
            </div>
        </section>
    )
}