import React, { useContext } from "react"
import PropTypes from 'prop-types';
import "./menu.css"
import LanguageContext from "../../../helpers/context"
import translate from "../../../helpers/translate"
import { Link } from "react-router-dom";

Menu.propTypes = {
    open: PropTypes.bool,
    openmenu: PropTypes.func,
};

export default function Menu({ open, openmenu, }) {
    const { language } = useContext(LanguageContext)
    

    return (
        <nav >
            <div className="navigation">
                <ul>
                    <li><Link  to="/" onClick={openmenu} unstable_viewTransition><h2>{translate(language, "main-home")}</h2></Link></li>
                    <li><Link  to="/about" onClick={openmenu} unstable_viewTransition><h2>{translate(language, "about")} </h2></Link></li>
                    <li><Link  to="/services" onClick={openmenu} unstable_viewTransition><h2>{translate(language, "services")}</h2></Link></li>
                    {/* <li><Link  to="/catalogue"  onClick={openmenu} unstable_viewTransition><h2>{translate(language, "catalogue")}</h2></Link></li> */}
                   <li> <Link to="/contacts" onClick={openmenu} unstable_viewTransition><h2>{translate(language, "contacts")}</h2></Link></li>

                </ul>
            </div>

            <div className="menu-contacts">
                info@lienesconsulting.com
                <br />
                +46 76 404 78 84
                <br />
                + 371 29 121 844
            </div >
        </nav >
    )
}