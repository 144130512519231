import React, { useContext } from "react"
import "./home.css"
import LanguageContext from "../../helpers/context"
import translate from "../../helpers/translate"
import About from "../../assets/consult1.jpeg"
import About2 from "../../assets/consult.jpeg"
import { Link } from "react-router-dom";

import Partners from "./partners/partners"
import Testimonials from "./testimonials/testimonials"
import MenuBar from "../menuBar/menuBar"

export default function Home() {
    const { language } = useContext(LanguageContext)

    const objects = [
        {
            title: "accounting",
            text: "main-text-accounting"
        },
        {
            title: "business-consultations",
            text: "main-business-consult"
        },
        {
            title: "business-contacts",
            text: "main-business-contacts"
        }
    ]

    return (
        <>
        <MenuBar />
        <section className="home">
            <div className="first-view" >
                <h1>{translate(language, "main-text")}</h1>
                <p>{translate(language, "main-subtext")}</p>
                <Link to="/contacts" className="link" unstable_viewTransition>{translate(language, "main-button")}</Link>
            </div>
            <div className="about">
                <div className="about1">
                    <span>
                        <h2>{translate(language, "about")}</h2>
                        <p>{translate(language, "main-text-about")}</p>
                    </span>
                    <img src={About} alt="accounting" width="300px" height="300px" />
                </div>
                <span className="about2">
                    <img src={About2} alt="accounting" width="300px" height="300px" />
                    <p>{translate(language, "main-text-about2")}</p>
                </span>

            </div>

            <div className="services">
                <h2>{translate(language, "services")}</h2>
                <span>
                    {objects.map(x => <div className="block" key={x.title}>
                        <h3>{translate(language, x.title)}</h3>
                        <p>{translate(language, x.text)}</p>
                        <Link to="/services" >{translate(language, "read-more")}</Link>
                    </div>
                    )}
                </span>
            </div>

            <Testimonials />
            <div className="partners">
                <h2>{translate(language, "partners")}</h2>
                <Partners />
            </div>

        </section>
        </>
    )
}