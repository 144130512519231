import React, {useContext} from "react"
import MenuBar from "../menuBar/menuBar"
import "./errorpage.css"
import LanguageContext from "../../helpers/context"
import translate from "../../helpers/translate"

export default function Errorpage() {
    const { language } = useContext(LanguageContext)

    return (
        <>
            <MenuBar />
            <section className="error">

                <p>{translate(language, "error")}</p>
            </section>
        </>
    )
}